import styled, { css } from 'styled-components'
import { layout, LayoutProps, space, SpaceProps, textAlign, TextAlignProps } from 'styled-system'

const Heading = styled.h1<LayoutProps & SpaceProps & TextAlignProps>`
    ${({ theme: { colors } }) => css`
        color: ${colors.gray[1]};
    `}
    ${layout}
    ${space}
    ${textAlign}
`

export const PrimaryHeading = styled(Heading)`
    ${({ theme: { fonts, breakpoints } }) => css`
        font: ${fonts.tertiaryHeading};

        @media (min-width: ${breakpoints['m']}) {
            font: ${fonts.secondaryHeading};
        }

        @media (min-width: ${breakpoints['l']}) {
            font: ${fonts.primaryHeading};
        }
    `}
`

export const PrimaryDisplayHeading = styled(Heading)`
    ${({ theme: { fonts, breakpoints } }) => css`
        // TODO: In disquission with UX guys and other teams, tertiary for mobile looks too small
        // font: ${fonts.tertiaryDisplayHeading};
        font: ${fonts.secondaryDisplayHeading};

        /* @media (min-width: ${breakpoints['m']}) {
            font: ${fonts.secondaryDisplayHeading};
        } */

        @media (min-width: ${breakpoints['l']}) {
            font: ${fonts.primaryDisplayHeading};
        }
    `}
`

export const SecondaryHeading = styled(Heading)`
    ${({ theme: { fonts, breakpoints } }) => css`
        font: ${fonts.quaternaryHeading};

        @media (min-width: ${breakpoints['m']}) {
            font: ${fonts.tertiaryHeading};
        }

        @media (min-width: ${breakpoints['l']}) {
            font: ${fonts.secondaryHeading};
        }
    `}
`

export const TertiaryHeading = styled(Heading)`
    ${({ theme: { fonts, breakpoints } }) => css`
        font: ${fonts.quaternaryHeading};

        @media (min-width: ${breakpoints['l']}) {
            font: ${fonts.tertiaryHeading};
        }
    `}
`

export const QuaternaryDisplayHeading = styled(Heading)`
    ${({ theme: { fonts } }) => css`
        font: ${fonts.quaternaryDisplayHeading};
    `}
`
