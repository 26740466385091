import React, { FC } from 'react'
import { DefaultTheme, StyledComponent } from 'styled-components'
import { LayoutProps, SpaceProps, TextAlignProps } from 'styled-system'
import { assertNever } from 'src/utils/typescript'
import * as S from './styles'

type Variant = 'primary' | 'primary-display' | 'secondary' | 'tertiary' | 'quaternary-display'
type Tag = 'h1' | 'h2' | 'h3'

interface Props extends LayoutProps, SpaceProps, TextAlignProps {
    variant: Variant
    tag: Tag
    children: React.ReactNode
}

export const getHeadingVariant = (variant: Variant): StyledComponent<Tag, DefaultTheme> => {
    switch (variant) {
        case 'primary':
            return S.PrimaryHeading
        case 'primary-display':
            return S.PrimaryDisplayHeading
        case 'secondary':
            return S.SecondaryHeading
        case 'tertiary':
            return S.TertiaryHeading
        case 'quaternary-display':
            return S.QuaternaryDisplayHeading
        default:
            return assertNever(variant)
    }
}

const Heading: FC<Props> = ({ variant, tag, children, ...rest }) => {
    const HeadingVariant = getHeadingVariant(variant)
    return (
        <HeadingVariant as={tag} {...rest}>
            {children}
        </HeadingVariant>
    )
}

export default Heading
