import React from 'react'
import ReactHtmlParser from 'react-html-parser'
import { useHistory } from 'react-router'
import { getHotportSuccess, getHotportLoading, getHotportWelcome } from 'src/api'
import { AbsoluteLink } from 'src/components/AbsoluteLink'
import Box from 'src/components/Box'
import { Button } from 'src/components/Button'
import { HotportPage } from 'src/components/HotportPage'
import { HotportText } from 'src/components/HotportText'
import { Loader } from 'src/components/Loader'
import { useAsync } from 'src/hooks/useAsync'
import { useDocumentTitle } from 'src/hooks/useDocumentTitle'
import {
    IHotportLoadingProps,
    IHotportSuccessProps,
    IHotportWelcomeProps,
} from 'src/typings/response'

export function Hotport() {
    const history = useHistory()
    const { data: dataWelcome, run: runWelcome } = useAsync<IHotportWelcomeProps>()
    const {
        data: dataLoading,
        run: runLoading,
        status: statusLoading,
    } = useAsync<IHotportLoadingProps>()
    const {
        data: dataSuccess,
        run: runSuccess,
        status: statusSuccess,
    } = useAsync<IHotportSuccessProps>()
    useDocumentTitle(dataWelcome?.tabTitle, [dataWelcome])

    // fetch welcome state
    React.useEffect(() => {
        runWelcome(getHotportWelcome())
    }, [])

    // fetch success state
    React.useEffect(() => {
        if (statusLoading === 'success') {
            runSuccess(getHotportSuccess())
        }
    }, [statusLoading])

    // move to error page
    React.useEffect(() => {
        if (statusSuccess === 'error') {
            history.push('/fehler')
        }
    }, [statusSuccess])

    if (!dataWelcome) {
        return <Loader fillParent />
    }

    const handleSuccess = () => {
        runLoading(getHotportLoading())
    }

    const {
        button,
        note: welcomeNote,
        text: welcomeText,
        title: welcomeTitle,
        impressumLink: welcomeLink,
    } = dataWelcome as IHotportWelcomeProps

    if (!dataLoading) {
        return (
            <HotportPage
                title={welcomeTitle}
                link={
                    <Box
                        textAlign="center"
                        mt={{
                            default: 76,
                            m: 106,
                        }}
                    >
                        <AbsoluteLink to={welcomeLink.url} target="_blank" data-qa="link_Impressum">
                            {welcomeLink.text}
                        </AbsoluteLink>
                    </Box>
                }
            >
                <HotportText variant="plain" mb={{ default: 3, m: 4 }} data-qa="label_Text">
                    {ReactHtmlParser(welcomeText)}
                </HotportText>
                <Button onClick={handleSuccess} data-qa="info_Button">
                    {button}
                </Button>
                <HotportText
                    variant="plain"
                    mt={{ default: 3, m: 4 }}
                    textAlign="center"
                    data-qa="label_Note"
                >
                    {ReactHtmlParser(welcomeNote)}
                </HotportText>
            </HotportPage>
        )
    }

    const {
        mainText,
        additionalText,
        title: loadingTitle,
        impressumLink: loadingLink,
        note: loadingNote,
        spinnerComment,
    } = dataLoading

    if (!dataSuccess) {
        return (
            <HotportPage
                title={loadingTitle}
                link={
                    <Box
                        textAlign="center"
                        mt={{
                            default: 76,
                            m: 106,
                        }}
                    >
                        <AbsoluteLink to={loadingLink.url} target="_blank" data-qa="link_Impressum">
                            {loadingLink.text}
                        </AbsoluteLink>
                    </Box>
                }
            >
                <HotportText variant="plain" mb={2} data-qa="label_LoadingText">
                    {ReactHtmlParser(mainText)}
                </HotportText>
                <HotportText variant="plain" mb="15px">
                    {additionalText}
                </HotportText>
                <Loader />
                <HotportText variant="plain" mt="15px" mb={2}>
                    {spinnerComment}
                </HotportText>
                <HotportText variant="plain" data-qa="label_LoadingNote">
                    {ReactHtmlParser(loadingNote)}
                </HotportText>
            </HotportPage>
        )
    }

    const {
        title: successTitle,
        text: successText,
        note: successNote,
        impressumLink: successLink,
        code,
        lineIdentifier,
    } = dataSuccess as IHotportSuccessProps

    return (
        <HotportPage
            title={successTitle}
            link={
                <Box
                    textAlign="center"
                    mt={{
                        default: 76,
                        m: 106,
                    }}
                >
                    <AbsoluteLink to={successLink.url} target="_blank" data-qa="link_Impressum">
                        {successLink.text}
                    </AbsoluteLink>
                </Box>
            }
        >
            <HotportText variant="plain" mb={{ default: 3, m: 4 }} data-qa="label_Text">
                {ReactHtmlParser(successText)}
            </HotportText>
            <Box display="flex" justifyContent="center" mb={1}>
                <HotportText
                    variant="plain-large"
                    minWidth="150"
                    textAlign="right"
                    mr={2}
                    data-qa="label_Code"
                >
                    {code.name}
                </HotportText>
                <HotportText
                    variant="plain-large"
                    minWidth="150"
                    textAlign="left"
                    data-qa="label_CodeValue"
                >
                    {code.value}
                </HotportText>
            </Box>
            <Box display="flex" justifyContent="center" mb={{ default: 3, m: 4 }}>
                <HotportText
                    variant="plain-large"
                    minWidth="150"
                    textAlign="right"
                    mr={2}
                    data-qa="label_LineId"
                >
                    {lineIdentifier.name}
                </HotportText>
                <HotportText
                    variant="plain-large"
                    minWidth="150"
                    textAlign="left"
                    data-qa="label_LineIdValue"
                >
                    {lineIdentifier.value}
                </HotportText>
            </Box>
            <HotportText variant="plain" data-qa="label_Note">
                {ReactHtmlParser(successNote)}
            </HotportText>
        </HotportPage>
    )
}
