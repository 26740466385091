import React from 'react'
import styled from 'styled-components'

const LinkTag = styled.a`
    text-decoration: none;
    font-family: 'Trebuchet MS', sans-serif;
    color: ${({ theme }) => theme.colors.gray[2]};
`

interface LinkProps {
    to: string
    target?: '_blank' | '_self' | '_parent' | '_top'
    children: React.ReactNode
}

export const AbsoluteLink = ({ to, target, children, ...rest }: LinkProps) => {
    return (
        <LinkTag {...rest} href={to} target={target}>
            {children}
        </LinkTag>
    )
}
