import { DefaultTheme } from 'styled-components'
import breakpoints from './breakpoints'
import bu from './buildingUnit'
import colors from './colors'
import fonts from './fonts'

const theme: DefaultTheme = {
    name: 'Telekom',
    breakpoints,
    bu,
    colors,
    fonts,
    space: [0, 12, 24, 36, 48, 60, 72, 84, 96],
}

export default theme
