import React from 'react'

function ErrorImage(): React.ReactElement {
    return (
        <svg
            viewBox="0 0 664 320"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            role="img"
        >
            <title>Fehler</title>
            <defs>
                <path id="a" d="M0 .252h55.522v11.74H0z" />
                <path
                    d="M19.82 141.856c-10.763 0-19.49-8.785-19.49-19.623V15.815C.33 11.217 7.434.155 12.002.155L43.992 0c4.568 0 11.861 11.217 11.861 15.815v106.418c0 10.838-8.726 19.623-19.49 19.623H19.82z"
                    id="c"
                />
            </defs>
            <g fill="none" fillRule="evenodd">
                <path fill="#FFF" d="M-313-414h1344v1469H-313z" />
                <g transform="translate(0 -9.036)">
                    <path
                        d="M597.895 239.425c-.15.855-.3 1.688-.45 2.52C586.68 302.35 555.17 328.9 555.17 328.9H82.434s-28.918-53.035-16.203-99.32c.192-.705.385-1.41.599-2.094 8.37-27.553 31.98-52.372 85.92-61.706a2498.804 2498.804 0 0032.13-5.767c1.392-.278 2.74-.534 4.089-.79 34.227-6.537 58.094-12.368 76.245-19.032h.022c1.327-.491 2.633-1.004 3.917-1.495 36.881-14.481 49.489-33.299 82.539-71.532a148.705 148.705 0 018.22-8.736c.641-.64 1.284-1.26 1.947-1.858 17.83-16.917 41.505-31.505 67.02-39.942.685-.235 1.37-.47 2.055-.683 32.688-10.338 68.197-10.488 98.229 7.603a99.165 99.165 0 013.403 2.136C556.348 41.34 576.383 69 588.35 112.808c14.298 52.394 14.898 94.237 9.546 126.617"
                        fill="#F2F5FF"
                    />
                    <g fillRule="nonzero">
                        <path
                            d="M314.452 137.032c0-4.028.416-7.961 1.108-11.8L293 106.481a92.59 92.59 0 0117.525-30.238l27.492 10.103c6.005-5.071 12.936-9.064 20.493-11.78l4.991-28.874A93.697 93.697 0 01381 44.036c5.983 0 11.822.581 17.499 1.664l4.984 28.873c7.557 2.717 14.478 6.71 20.48 11.781l27.512-10.104A92.733 92.733 0 01469 106.49l-22.56 18.75c.692 3.839 1.108 7.768 1.108 11.8 0 4.029-.416 7.965-1.108 11.794L469 167.586c-3.906 11.217-9.909 21.453-17.525 30.252l-27.511-10.11c-6.003 5.071-12.924 9.064-20.48 11.78l-4.985 28.88A94.654 94.654 0 01381 230.036c-5.99 0-11.829-.581-17.499-1.658l-4.991-28.88c-7.557-2.716-14.484-6.709-20.493-11.78l-27.492 10.11A92.804 92.804 0 01293 167.576l22.56-18.75c-.692-3.829-1.108-7.758-1.108-11.794zM381 176.89c22.04 0 39.929-17.843 39.929-39.858 0-22.02-17.888-39.857-39.929-39.857-22.054 0-39.929 17.836-39.929 39.857 0 22.015 17.875 39.858 39.929 39.858z"
                            fill="#828AC4"
                        />
                        <path
                            d="M213.77 245.533c0-3.487.364-6.891.97-10.215L195 219.088a79.947 79.947 0 0115.335-26.173l24.054 8.745c5.256-4.39 11.32-7.845 17.932-10.197l4.368-24.993A82.848 82.848 0 01272 165.036a82.45 82.45 0 0115.311 1.44l4.362 24.993c6.612 2.351 12.668 5.807 17.92 10.197l24.072-8.746A80.07 80.07 0 01349 219.094l-19.74 16.23c.606 3.323.97 6.724.97 10.215 0 3.487-.364 6.894-.97 10.209L349 271.98c-3.418 9.709-8.67 18.57-15.335 26.186l-24.072-8.752c-5.252 4.39-11.308 7.846-17.92 10.198l-4.362 24.998A83.695 83.695 0 01272 326.036c-5.24 0-10.35-.503-15.311-1.435l-4.368-24.998c-6.612-2.352-12.673-5.807-17.932-10.198l-24.054 8.752A80.13 80.13 0 01195 271.97l19.74-16.229c-.606-3.315-.97-6.716-.97-10.21zM272 279.077c19.096 0 34.594-15.516 34.594-34.66 0-19.149-15.498-34.659-34.594-34.659-19.108 0-34.594 15.51-34.594 34.66 0 19.143 15.486 34.66 34.594 34.66z"
                            fill="#6973A8"
                        />
                    </g>
                    <g fillRule="nonzero">
                        <path
                            d="M592.092 313.266c2.89-.113 6.163.943 2.063 7.678-6.273 10.304-15.088 7.772-15.452 7.662l-.012-.004 10.273-14.84s1.341-.386 2.859-.482zm-17.544 15.692s-8.866-21.87 8.215-29.512c0 0 10.515-8.152 12.822-20.183l.077-.421s-2.932 25.874-21.114 50.116zm-58.932-45.462l8.022.639a32.073 32.073 0 0123.826 13.715l19.799 28.597c-3.67-3.822-9.647-6.048-14.018-7.243a60.506 60.506 0 01-12.593-4.992l-.338-.188a13.64 13.64 0 01-5.766-6.467l-.308-.707a57.668 57.668 0 00-14.96-20.183l-3.664-3.17zm23.18-18.003l8.92 5.118a19.057 19.057 0 019.058 12.13l5.473 23.068c-16.339-5.533-17.127-26.35-17.127-26.35-11.426-3.14-6.742-13.122-6.349-13.916l.019-.037.006-.013zm79.08-1.419s2.116 6.489-3.402 8.772a7.355 7.355 0 00-4.03 4.198c-1.755 4.613-5.953 12.312-14.713 13.735l6.842-16.253a14.175 14.175 0 018.132-7.79l7.17-2.662z"
                            fill="#6973A8"
                        />
                        <path
                            d="M598.263 261.765s-.312 6.713-2.602 17.087l-.002.011c-.191.865-.396 1.754-.615 2.668-3.073 10.776-12.281 17.915-12.281 17.915-16.91 7.565-8.39 29.076-8.218 29.506l.003.006s-20.29-29.776 6.851-39.262l-.018-.08c-.38-1.724-6.377-30.407 16.882-27.851zm32.074 23.886l-3.663 3.17a57.668 57.668 0 00-14.96 20.184l-.308.706a13.641 13.641 0 01-5.766 6.468l-.339.187a60.505 60.505 0 01-12.593 4.993c-4.37 1.195-10.347 3.42-14.017 7.243l19.798-28.597a32.073 32.073 0 0123.827-13.716l8.021-.638zM556.73 311.54l.1.026.16.043 10.274 14.84s-9.058 2.865-15.464-7.659c-5.83-9.576 3.248-7.67 4.93-7.25zm8.786-20.76c-10.47-22.993-4.487-32.35 0-30.853 3.562 1.187 1.47 5.671.455 7.472l-.116.202c-.202.347-.339.552-.339.552 8.601 10.097 0 22.627 0 22.627z"
                            fill="#D1D8F2"
                        />
                    </g>
                    <rect fill="#D1D8F2" x="45" y="327.036" width="584" height="2" rx="1" />
                    <rect fill="#D1D8F2" x="9" y="327.036" width="32" height="2" rx="1" />
                    <rect fill="#D1D8F2" y="327.036" width="6" height="2" rx="1" />
                    <g fillRule="nonzero">
                        <path
                            d="M134.52 56.43v25.44c0 2.872 2.865 5.2 6.4 5.2s6.4-2.328 6.4-5.2V56.43c0-1.38-.674-2.702-1.874-3.677-1.2-.975-2.829-1.523-4.526-1.523-.132 0-.264.003-.394.01-3.375.168-6.007 2.442-6.006 5.19z"
                            fill="#FFCEA9"
                        />
                        <path
                            d="M140.526 51.24v19.35c0 1.38.674 2.702 1.875 3.677 1.2.975 2.828 1.523 4.525 1.523.132 0 .264-.003.394-.01V56.43c0-1.38-.674-2.702-1.874-3.677-1.2-.975-2.829-1.523-4.526-1.523-.132 0-.264.003-.394.01z"
                            fill="#F4BA92"
                        />
                        <g transform="matrix(-1 0 0 1 155.08 32.356)">
                            <path
                                d="M2.037 12.818l4.58 22.33 11.116-1.055s2.495 4.136 3.05 2.136c0 0 1.053-3.797 4.176-4.256 2.381-.35 1.722-3.468 4.928-6.967 2.929-3.197.532-6.55.532-6.55 3.317-4.936-2.47-4.836-2.648-9.058a5.144 5.144 0 00-.358-1.685c-1.258-3.218-5.498-5.141-7.98-5.138 0 0-6.717-5.882-12.369.135 0 0-3.277-.488-5.786 3.284-2.756 4.147.76 6.824.76 6.824z"
                                fill="#D6856B"
                            />
                            <path
                                d="M.156 25.447a.874.874 0 00-.029.951c.274.434.645.798 1.084 1.063.2.127.411.233.632.317.167.647.41 5.302.571 5.883.348 1.261-1.118 2.776 1.09 4.404 2.207 1.63 10.781.55 14.064-1.72.255-.177.494-.376.714-.594 2.519-2.467 2.252-6.843 2.252-6.843 4.834-2.981 5.093-8.331 2.177-8.669-2.916-.337-3.514 2.71-3.514 2.71-2.555-.701-.832-5.303-.832-5.303-1.597-.18-.497-3.495-.497-3.495-3.006.104-5.259-3.669-5.259-3.669a2.873 2.873 0 01-1.533-.009c-1.754-.451-3.232-2.18-3.232-2.18-4.837-.454-5.455 2.083-5.455 2.083L.37 17.963l.766 3.936a2.424 2.424 0 00-.45 2.074l.129.532-.658.942z"
                                fill="#FFCEA9"
                            />
                            <path
                                d="M7.923 30.486c-2.197 2.323-5.25 1.225-5.25 1.225l-.055-.758-.066-.881 1.465-.18 2.59.394 1.316.2z"
                                fill="#4C002D"
                            />
                            <path
                                d="M7.26 30.317c-.159.24-.411.4-.695.44a16.474 16.474 0 01-3.576.114l-.465-.036-.079-.77s1.559-.186 4.815.252z"
                                fill="#FFF"
                            />
                            <path
                                d="M8.157 30.539a27.541 27.541 0 00-5.76-.305.499.499 0 01.459-.59c.558-.097 2.216-.178 5.301.895z"
                                fill="#F4BA92"
                            />
                            <path
                                d="M2.65 31.39a.742.742 0 00-.142.557s1.338.973 3.543.03c0 0 1.28-.46 1.872-1.491l-.14-.022a4.913 4.913 0 01-5.133.925zM5.248 38.83l3.505.108c6.117-.17 8.555-2.313 10.012-3.594l.214-.188c1.496-1.301 1.563-5.785 1.574-5.975h-.069c-.003.045-.276 4.54-1.684 5.765l-.216.189c-1.428 1.256-3.82 3.358-9.835 3.525l-3.492-.107-.009.277z"
                                fill="#F4BA92"
                            />
                            <circle
                                fill="#4C002D"
                                transform="rotate(-85.668 1.75 20.934)"
                                cx="1.751"
                                cy="20.934"
                                r="1"
                            />
                            <path
                                d="M4.23 21.126s-1.978-1.924-3.2-1.146l3.2 1.146z"
                                fill="#4C002D"
                            />
                            <path
                                fill="#D6856B"
                                d="M.986 15.643l1.304-.954.38 2.217-2.301 1.057zM4.616 13.937l-.191 2.515s3.604-1.179 8.304 1.26c0 0-1.91-3.734-8.113-3.775z"
                            />
                            <path
                                d="M2.67 16.906s.863 3.837-1.87 8.186l2.863 1.386 1.678-7.366-2.671-2.206z"
                                fill="#FFCEA9"
                            />
                            <path
                                d="M1.418 24.15c1.988-3.969 1.393-7.238 1.386-7.27l-.27.053c.007.031.787 3.228-1.155 7.108l.04.11z"
                                fill="#F4BA92"
                            />
                            <ellipse
                                fill="#4C002D"
                                transform="rotate(-85.671 8.41 20.877)"
                                cx="8.41"
                                cy="20.877"
                                rx="1.059"
                                ry="1"
                            />
                            <path
                                d="M11.026 20.898s-2.501-2.285-4.036-1.373l4.036 1.373z"
                                fill="#4C002D"
                            />
                            <path
                                d="M3.922 28.095c.405.017.8-.128 1.098-.404a.924.924 0 00.208-1.17l-.24.136c.16.278.094.63-.156.832-.339.32-.898.428-1.198.23-.701-.462-1.79-.09-1.836-.073l.09.262c.01-.003 1.008-.344 1.596.044a.915.915 0 00.438.143z"
                                fill="#F4BA92"
                            />
                        </g>
                        <g>
                            <path
                                d="M128.807 307.519l-1.766 3.3c-1.668 3.12 1.923 6.998 5.06 8.644 0 0 .148-.014.408-.028 4.264-.213 8.041 2.721 8.876 6.896h-20.514l-13.627-8.66 8.386-16.423 13.177 6.27z"
                                fill="#6973A8"
                            />
                            <path
                                d="M141.65 309.11v17.32h36.537a5.417 5.417 0 00-1.393-3.275 12.177 12.177 0 00-5.99-3.595l-5.848-1.492a8.826 8.826 0 01-4.05-2.301l-.665-.662a8.681 8.681 0 01-2.359-4.324l-.359-1.671H141.65z"
                                fill="#828AC4"
                            />
                            <path
                                d="M137.046 150.615a18.181 18.181 0 0113.02 6.043 18.074 18.074 0 014.545 13.583l-4.861 57.279a15.164 15.164 0 01-.758 3.641c-.058.649-.16 1.297-.305 1.94l-.18.793-17.847 79.146-19.672-8.06 8.118-76.262.122-.9c.039-.29.087-.58.143-.866l-1.011-57.922a18.08 18.08 0 015.391-13.194 18.188 18.188 0 0113.295-5.22z"
                                fill="#3F0029"
                            />
                            <path
                                d="M138.84 150.297c11.412 0 20.664 9.225 20.664 20.605 0 1.061-.08 2.104-.236 3.122l4.117 52.306c.012.145.022.29.03.435.183 1.06.252 2.145.2 3.237l-.038.813-3.774 81.034H139.14l-5.302-79.297a15.262 15.262 0 01-.506-1.797l-8.103-44.35c-4.323-3.776-7.053-9.322-7.053-15.503 0-11.38 9.252-20.605 20.665-20.605z"
                                fill="#4C002D"
                            />
                        </g>
                        <g>
                            <path
                                d="M128.87 89.956a7.515 7.515 0 0110.013-3.098l41.863 21.355c1.85-.242 4.243-.407 6.717-.52l.757-.033c2.575-.106 5.186-.158 7.317-.183-.136-1.148-.044-2.305.737-3.815.725-1.41.344-3.454 1.086-4.56 3.22-4.798 5.573.001 5.787.031l.007-.003-.033.115c-.385 1.027-2.297.307-2.188 1.512a2.765 2.765 0 002.23 2.475c.952.19 2.536.53 3.774 1.18l.996-.1h.03c.263.002 2.23.074 1.743 1.62a2.21 2.21 0 01-.96 1.194l-.002.417c.602.155 1.269.545.962 1.519a2.21 2.21 0 01-.96 1.193l-.002.417c.602.156 1.269.545.962 1.519a2.21 2.21 0 01-.96 1.193l-.002.417c.602.156 1.269.545.962 1.519a2.21 2.21 0 01-1.313 1.363c-1.214.483-3.828 1.242-7.658.88a1.834 1.834 0 01-.839-.279 9.146 9.146 0 01-.709-.138c-4.736 2.173-11.864 3.872-15 4.772-1.184.34-2.558.532-3.957.479-1.66.38-3.457.2-5.089-.632L131.883 99.7a7.115 7.115 0 01-3.014-9.743z"
                                fill="#F4BA92"
                            />
                            <path
                                d="M199.607 99.923a1.835 1.835 0 001.13.501l-.964.098c-.617.43-.772.948-.724 1.427a1.837 1.837 0 001.689 1.604l-.965.099c-.617.43-.772.947-.724 1.426a1.837 1.837 0 001.689 1.604l-.965.099c-.617.43-.772.947-.724 1.426.07.56.39 1.04.842 1.325-3.49-.546-3.546-2.3-3.677-4.987l-.009-.173a12.248 12.248 0 013.156-4.241z"
                                fill="#703A3A"
                            />
                            <path
                                d="M132.448 74.519l18.6 5.994 4.538 9.775a55.606 55.606 0 015.168 22.927l.495 56.615s-20.701 5.395-44.702-2.698l2.7-27.274V103.47c0-4.102.469-8.191 1.398-12.187a27.298 27.298 0 0111.803-16.765z"
                                fill="#CEB2B9"
                            />
                        </g>
                    </g>
                    <path
                        fill="#9F9F9F"
                        d="M189.922 136.132l6.176 7.128 104.633-91.255-6.176-7.128z"
                    />
                    <g transform="rotate(49 -3.384 269.161)">
                        <mask id="b" fill="#fff">
                            <use xlinkHref="#a" />
                        </mask>
                        <path
                            d="M49.927 11.992H5.595c-2.117 0-3.834-1.845-3.834-4.121V4.373c0-2.276 1.717-4.121 3.834-4.121h44.332c2.118 0 3.834 1.845 3.834 4.12v3.499c0 2.276-1.716 4.12-3.834 4.12"
                            fill="#E20074"
                            mask="url(#b)"
                        />
                    </g>
                    <path
                        fill="#B00062"
                        d="M117.528 181.47l23.62 27.172 82.685-71.878-23.62-27.171z"
                    />
                    <g transform="rotate(49 -35.02 254.306)">
                        <mask id="d" fill="#fff">
                            <use xlinkHref="#c" />
                        </mask>
                        <use fill="#E20074" xlinkHref="#c" />
                        <path
                            d="M0 126.873v-104a7 7 0 0114 0v104a7 7 0 01-14 0zM24.658 127.065v-104a7 7 0 1114 0v104a7 7 0 11-14 0zM52.972 126.503v-104a7 7 0 1114 0v104a7 7 0 01-14 0z"
                            fill="#B00062"
                            mask="url(#d)"
                        />
                        <path
                            d="M48.74 126.799v-104a7 7 0 0114 0v104a7 7 0 01-14 0z"
                            fill="#B00062"
                            mask="url(#d)"
                        />
                    </g>
                    <path
                        fill="#C4C4C4"
                        d="M330.884 26.272l-6.355-7.31-26.696 14.27-2.94 11.492 6.355 7.31 11.79-1.312z"
                    />
                    <path
                        d="M135.45 83.487a9.062 9.062 0 0110.784 6.925l8.932 40.994 2.866 1.759 28.637 22.252c.187.047.373.103.558.167l8.377 2.903 3.202 1.071a7.196 7.196 0 013.654 2.765l2.552 3.724a1.616 1.616 0 01-.002 1.84l-.099.141s1.213.53.108 1.84c-1.102 1.312-2.12.619-2.12.619s-.075 2.38-2.72 1.927c0 0-.466 2.095-2.392 1.522 0 0-.555 2.35-2.983 1.002-.891-.495-2.18-1.494-3.453-2.561l-.424-.358c-2.038-1.733-3.918-3.515-3.918-3.515l-2.358-3.026a4.054 4.054 0 00-.647-.63 4.51 4.51 0 01-.623-.23l-34.85-15.816-6.166-3.786a9.147 9.147 0 01-4.28-6.57l-.053-.235-9.557-43.861a9.128 9.128 0 016.975-10.863z"
                        fill="#FFCEA9"
                        fillRule="nonzero"
                    />
                    <path
                        d="M136.346 81.21c4.833-1.06 9.613 1.99 10.675 6.813l6.038 27.413-20.81 4.564-6.038-27.413c-1.062-4.823 1.994-9.591 6.828-10.651zm18.655 7.826L168 95.66l-7.353 14.376a57.225 57.225 0 00-4.861-19.297l-.32-.703-.465-1.001z"
                        fill="#B2969C"
                        fillRule="nonzero"
                    />
                    <g fillRule="nonzero">
                        <path
                            d="M443.466 300.566v6.831l-.227 5.01c-.346 2.69-8.239 7.281-12.404 9.335-1.289.635-2.434 1.072-3.24 1.207-4.625.775-4.997 3.168-5.015 3.775v.094a.76.76 0 00.002.047h20.424l2.672-2.122a2.646 2.646 0 011.653-.585 2.68 2.68 0 011.91.789c.507.508.791 1.198.79 1.918l2.038-.114.906-12.523c.022-.24.112-5.205.136-5.465l.77-8.197h-10.415zm31.866 0l.77 8.197c.023.26.113 5.225.135 5.465l.906 12.523 2.037.114c0-.72.284-1.41.791-1.918a2.68 2.68 0 011.91-.79c.601.002 1.184.209 1.653.586l2.672 2.122h20.424s.002-.016.002-.047v-.094c-.018-.607-.39-3-5.015-3.775-.806-.135-1.951-.572-3.24-1.207-4.165-2.054-12.058-6.644-12.404-9.336l-.227-5.009v-6.83h-10.414z"
                            fill="#EFA88F"
                        />
                        <path
                            d="M452.975 314.228c-3.84-.521-5.434 1.822-5.434 1.822l-4.547 3.464a10.876 10.876 0 01-6.593 2.228h-5.566c-1.289.635-2.434 1.072-3.24 1.207-4.625.775-4.997 3.168-5.015 3.775v.094a.76.76 0 00.002.047h20.424l2.672-2.122a2.646 2.646 0 011.653-.585 2.68 2.68 0 011.91.789c.507.508.791 1.198.79 1.918h2.414c.395-2.643.52-11.94.53-12.6zm23.262 0v.037c.01.66.135 9.957.53 12.6h2.413c0-.72.284-1.41.791-1.918a2.68 2.68 0 011.91-.79c.601.002 1.184.209 1.653.586l2.672 2.122h20.424s.002-.016.002-.047v-.094c-.018-.607-.39-3-5.015-3.775-.806-.135-1.951-.572-3.24-1.207h-5.566c-2.38 0-4.695-.782-6.593-2.228l-4.547-3.464s-1.594-2.343-5.434-1.822z"
                            fill="#4C002D"
                        />
                        <path
                            d="M451.109 144.365l-.006.104-2.305.01s-6.113 16.167-8.15 38.709c-2.038 22.542-3.057 125.575-3.057 125.575h18.792l4.498-73.727a182.17 182.17 0 013.168-21.67 270.056 270.056 0 015.26 34.306l3.535 64.051h18.565s-.32-33.24-.959-66.449l-.07-3.556c-.496-24.874-1.172-49.063-2.028-58.53-1.997-22.091-7.907-38.06-8.143-38.69l-.007-.019-2.304-.01-.007-.104-13.391.052-13.391-.052z"
                            fill="#B2969C"
                        />
                        <g>
                            <path
                                d="M459.037 91.154c0 2.414 2.406 4.372 5.375 4.372s5.375-1.958 5.375-4.372V64.283c0-2.2-2.009-4.057-4.691-4.336a6.424 6.424 0 00-.684-.037c-1.426 0-2.793.46-3.801 1.28-1.008.82-1.575 1.933-1.574 3.093v26.87z"
                                fill="#FFCEA9"
                            />
                            <path
                                d="M459.037 64.283V84.2c.226.025.455.037.684.037 1.426 0 2.793-.46 3.802-1.28 1.008-.82 1.574-1.933 1.573-3.092V59.947a6.424 6.424 0 00-.684-.037c-1.426 0-2.793.46-3.801 1.28-1.008.82-1.575 1.933-1.574 3.093z"
                                fill="#F4BA92"
                            />
                            <g transform="matrix(-1 0 0 1 490.814 45.253)">
                                <path
                                    d="M16.596 8.238a23.993 23.993 0 011.773-1.26c4.227-2.71 8.156-3.116 12.16-2.19 4.56 1.05 5.458 4.43 5.458 4.43l3.2 7.856-.552 2.876.467.838c.45.808.572 1.76.34 2.656l-.768 2-.068.175c-.239.628.219 1.895.219 1.895a3.268 3.268 0 00-.378 1.138c.482.222.416 1.002.278 1.182-.5.461-.774 1.12-.75 1.802l.002.071c.28 1.719-.663 3.585-2.394 3.62-1.31.026-4.536-.54-6.535-1.77h-.002c-5.51-3.211-5.849-5.016-5.849-5.016s-4.516.481-7.686-4.626c-3.168-5.106-3.83-11.842 1.085-15.677z"
                                    fill="#FFCEA9"
                                />
                                <path
                                    d="M37.154 19.314s.45-1.53 1.834-.765l-1.834.765z"
                                    fill="#4C002D"
                                />
                                <circle
                                    fill="#4C002D"
                                    transform="rotate(-9.678 38.376 19.803)"
                                    cx="38.376"
                                    cy="19.803"
                                    r="1"
                                />
                                <path
                                    d="M36.95 18.201l3.268 5.744c.133.223.142.5.024.73-.246.469-1.112 1.423-3.116.971"
                                    fill="#FFCEA9"
                                />
                                <path
                                    d="M36.95 18.201c.416.807.85 1.604 1.287 2.4.437.796.887 1.584 1.33 2.376l.34.59.17.294c.058.09.106.186.141.287.059.2.023.416-.098.586a2.019 2.019 0 01-.437.482 2.284 2.284 0 01-.57.327 2.871 2.871 0 01-.645.158 4.29 4.29 0 01-1.342-.055c.44.123.896.171 1.35.142.23-.02.456-.067.675-.14.219-.078.426-.187.614-.324.19-.138.354-.31.484-.508a.842.842 0 00.124-.713 1.456 1.456 0 00-.155-.324l-.167-.297-.333-.593c-.455-.785-.903-1.575-1.364-2.356-.461-.781-.925-1.561-1.405-2.332z"
                                    fill="#F4BA92"
                                />
                                <path
                                    d="M27.008 17.994s1.733-3.967 7.799-4.087l.246 1.455s-2.81-.928-8.045 2.632zM39.052 16.739l-1.673-.701-.26-1.53c.472.192.918.442 1.328.746l.605 1.485z"
                                    fill="#D6856B"
                                />
                                <path
                                    d="M30.072 19.97s1.936-2.454 3.632-1.516l-3.632 1.516z"
                                    fill="#4C002D"
                                />
                                <circle
                                    fill="#4C002D"
                                    transform="rotate(-9.678 32.465 20.16)"
                                    cx="32.465"
                                    cy="20.16"
                                    r="1"
                                />
                                <path
                                    d="M34.942 28.17s1.663 1.977 3.783 1.664c0 0 .44-.969-.278-1.181l-3.505-.483z"
                                    fill="#F4BA92"
                                />
                                <path
                                    d="M34.858 28.199s2.888.716 3.59.454c.241-.091.835-.813.431-1.207a.497.497 0 01-.541.128l-.528-.2a1.329 1.329 0 00-.75.059l-2.202.766z"
                                    fill="#F4BA92"
                                />
                                <path
                                    d="M.416 11.757a7.666 7.666 0 004.514 4.678 7.604 7.604 0 006.463-.49c.032-.015.06-.035.092-.052l-.235 6.286-.04 1.061c-.463 7.763 5.363 9.967 7.671 10.536.784.19 1.555.43 2.308.722l.229.088c2.631-3.076 1.136-6.696 1.136-6.696-1.222.043-2.478-.888-3.374-2.114-1.44-1.974-1.944-4.706.147-5.346 3.387-1.038 4.199 2.606 4.199 2.606 1.05-1.361.187-3.08.12-3.21l1.883 1.762c1.036-3.02-1.047-3.865-1.047-3.865 1.704-.73 2.045-2.739 2.032-4.285a2.682 2.682 0 012-2.641c5.165-1.302 8.044-.155 8.044-.155-.371-1.3-.913-3.163-1.965-4.005C28.968 2.126 23.26 3.201 18.32 4.63c-1.117.315-2.176.81-3.137 1.464C14.61.646 9.823.005 9.767 0l-.04.341-.04.341c.194.026 4.086.565 4.766 5.088C12.63 2.174 8.354.608 4.66 2.184.965 3.759-.888 7.94.416 11.757z"
                                    fill="#D6856B"
                                />
                                <path
                                    d="M.166 11.2c.83 3.024 3.73 5.348 7.298 5.545a8.49 8.49 0 003.692-.613.458.458 0 00.021-.051c.248-1.391.64-2.752 1.17-4.06 0 0-.352-2.174-3.587-2.223a6.294 6.294 0 00-3.652 1.15c-1.062.733-2.912 1.531-4.942.251zM18.79 33.585c.806.201 1.598.45 2.373.748l.235.09c2.83-2.83 1.393-6.293 1.393-6.293-1.27.006-2.543-.91-3.434-2.093-6.282 2.677-7.79-2.012-8.117-3.626l-.076 1.002c-.728 7.32 5.248 9.57 7.627 10.172z"
                                    fill="#C17561"
                                />
                            </g>
                        </g>
                        <g>
                            <path
                                d="M459.27 85.106s-4.084.226-5.78.871l-8.28 3.153c-.685.261-1.344.577-1.97.944a6.407 6.407 0 00-5.961 5.305l-4.004 22.827 9.458 1.678 5.323 14.05-6.354 27.693c25.233 9.447 45.861 0 45.861 0l-2.763-27.693 3.089-13.446 6.8-.287-.967-23.16c-.148-3.547-3.128-6.303-6.656-6.154l-.946.04a13.172 13.172 0 00-2.499-1.355l-8.086-3.315c-1.806-.74-5.785-1.151-5.785-1.151l-5.556 7.873z"
                                fill="#E20074"
                            />
                            <path
                                d="M453.558 77.624l-2.364 9.228 2.586 9.374a3.065 3.065 0 00-1.01 1.157l-11.206 22.294-7.332-1.3-1.655 9.426a6.552 6.552 0 006.34 7.685c2.09.65 4.339.621 6.42-.093.476 1.771 1.024 2.821 1.024 2.821l9.82-2.058 30.872.853a6.96 6.96 0 004.7-1.657 6.538 6.538 0 002.68-5.558l-.4-9.566-13.064.55.13 3.097-26.555 4.349a31.645 31.645 0 00-2.29-1.02l6.195-27.57c.07-.086.136-.177.197-.272l1.073-1.697a5.7 5.7 0 00.199-5.751l-3.332-6.176s1.232-6.45-.058-8.116h-2.97z"
                                fill="#FFCEA9"
                            />
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}

export default ErrorImage
