import styled, { css } from 'styled-components'
import { layout, space, textAlign } from 'styled-system'

const hotportTextBase = css`
    margin: 0;
    font-family: 'Trebuchet MS', sans-serif;
    color: ${({ theme }) => theme.colors.gray[0]};
`

export const HotportTextBase = styled.p`
    ${hotportTextBase}
    ${layout}
    ${space}
    ${textAlign}
`

export const HotportTextPlain = styled(HotportTextBase)`
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    white-space: pre-line;
`

export const HotportTextPlainLarge = styled(HotportTextBase)`
    font-weight: 700;
    ${({ theme: { breakpoints } }) => css`
        font-size: 18px;
        line-height: 21px;

        @media (min-width: ${breakpoints['m']}) {
            font-size: 24px;
            line-height: 28px;
        }
    `}
`

export const HotportTextHeading = styled(HotportTextBase)`
    font-size: 32px;
    font-weight: 700;
    line-height: 54px;
`

export const HotportTextHeadingSmall = styled(HotportTextBase)`
    font-size: 18px;
    font-weight: 700;
    line-height: 20px;
`

export const HotportTextContainer = styled.div`
    position: absolute;
    left: 50%;
    top: 0;
    transform: translate(-50%, -50%);
    padding: 0 20px;
    background-color: ${({ theme }) => theme.colors.white};
`
