import React, { FC } from 'react'
import { DefaultTheme, StyledComponent } from 'styled-components'
import { LayoutProps, SpaceProps, TextAlignProps } from 'styled-system'
import { assertNever } from 'src/utils/typescript'
import * as S from './styles'

type Variant =
    | 'copy'
    | 'copy-highlight'
    | 'additional'
    | 'additional-highlight'
    | 'additional-micro'
type Tag = 'span' | 'p'

export interface CopyTextProps extends LayoutProps, SpaceProps, TextAlignProps {
    variant: Variant
    color?: string
    children?: React.ReactNode
    tag?: Tag
}

export const getTextVariant = (variant: Variant): StyledComponent<Tag, DefaultTheme> => {
    switch (variant) {
        case 'copy':
            return S.Copy
        case 'copy-highlight':
            return S.CopyHighlight
        case 'additional':
            return S.Additional
        case 'additional-highlight':
            return S.AdditionalHighlight
        case 'additional-micro':
            return S.AdditionalMicro
        default:
            return assertNever(variant)
    }
}

const CopyText: FC<CopyTextProps> = ({ variant, tag, children, ...rest }) => {
    const TextVariant = getTextVariant(variant)
    return (
        <TextVariant as={tag} {...rest}>
            {children}
        </TextVariant>
    )
}

export default CopyText
