import React from 'react'
import styled from 'styled-components'
import { pagePaths } from '../routes/bng'

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 50vh;
    padding: 0 0.5rem;
`

const Main = styled.main`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5rem 0;
`

function Home() {
    return (
        <Container>
            <Main>
                <table>
                    <thead>
                        <tr>
                            <td>Entry Point</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <a href={pagePaths.defectLine}>BNG Defect Line</a>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <a href={pagePaths.easyLoginDeactivated}>
                                    BNG Easy Login Deactivated
                                </a>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <a href={pagePaths.hotport}>Hotport</a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </Main>
        </Container>
    )
}

export default Home
