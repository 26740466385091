import axios from 'axios'
import { paths } from 'src/routes/app'
import { apiPaths } from 'src/routes/bng'
import {
    IBngDefectLineProps,
    IBngEasyLoginDeactivatedProps,
    IHotportErrorProps,
    IHotportLoadingProps,
    IHotportSuccessProps,
    IHotportImprintProps,
    IHotportWelcomeProps,
} from 'src/typings/response'

const API = axios.create({
    baseURL:
        process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test'
            ? paths.bffDevApi
            : process.env.REACT_APP_API_PATH,
})

export const getBngDefectLine = (): Promise<IBngDefectLineProps> =>
    API.get(apiPaths.getDefectLine).then(response => response.data)

export const getBngEasyLognDeactivated = (): Promise<IBngEasyLoginDeactivatedProps> =>
    API.get(apiPaths.getEasyLoginDeactivated).then(response => response.data)

export const getHotportWelcome = (): Promise<IHotportWelcomeProps> =>
    API.get(apiPaths.getHotportWelcome).then(response => response.data)

export const getHotportLoading = (): Promise<IHotportLoadingProps> =>
    API.get(apiPaths.getHotportLoading).then(response => response.data)

export const getHotportError = (): Promise<IHotportErrorProps> =>
    API.get(apiPaths.getHotportError).then(response => response.data)

export const getHotportSuccess = (): Promise<IHotportSuccessProps> =>
    API.get(apiPaths.getHotportSuccess).then(response => response.data)

export const getHotportImprint = (): Promise<IHotportImprintProps> =>
    API.get(apiPaths.getHotportImprint).then(response => response.data)
