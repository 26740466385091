export interface Colors {
    magenta: string
    white: string
    black: string
    darkBlue: string
    lightBlue: string
    gray: {
        [color: number]: string
    }
    function: {
        blue: string
        yellow: string
        green: string
        red: string
        blueLight: string
        yellowLight: string
    }
    secondary: {
        yellow: string
        yellowLight: string
        yellowDark: string
        green: string
        greenLight: string
        greenDark: string
        orange: string
        orangeLight: string
        orangeDark: string
        petrol: string
        petrolLight: string
        petrolDark: string
        lightBlue: string
        lightBlueLight: string
        lightBlueDark: string
        darkBlue: string
        darkBlueLight: string
        darkBlueDark: string
    }
}

const colors: Colors = {
    magenta: '#e20074',
    white: '#ffffff',
    black: '#000000',
    darkBlue: '#00739f',
    lightBlue: '#31c3f7',
    gray: {
        0: '#191919',
        1: '#262626',
        2: '#383838',
        3: '#4b4b4b',
        4: '#6b6b6b',
        5: '#737373',
        6: '#7c7c7c',
        7: '#a3a3a3',
        8: '#b2b2b2',
        9: '#d0d0d0',
        10: '#dcdcdc',
        11: '#ededed',
        12: '#f8f8f8',
    },
    function: {
        blue: '#009de0',
        yellow: '#fecb00',
        green: '#46a800',
        red: '#d90000',
        blueLight: '#e5f5fd',
        yellowLight: '#fff7d9',
    },
    secondary: {
        yellow: '#ffd329',
        yellowLight: '#ffde5d',
        yellowDark: '#dbad39',
        green: '#bfcb44',
        greenLight: '#cfd873',
        greenDark: '#889130',
        orange: '#ff9a1e',
        orangeLight: '#ffb356',
        orangeDark: '#d48936',
        petrol: '#1bada2',
        petrolLight: '#65c8c0',
        petrolDark: '#218076',
        lightBlue: '#53baf2',
        lightBlueLight: '#7ecbf5',
        lightBlueDark: '#317cb3',
        darkBlue: '#1063ad',
        darkBlueLight: '#529ad6',
        darkBlueDark: '#235482',
    },
}

export default colors
