export const pagePaths = {
    defectLine: '/mein-kundencenter/bng/wg5x-landingpage',
    easyLoginDeactivated: '/mein-kundencenter/bng/wg54-landingpage',
    hotport: '/',
    hotportError: '/fehler',
    hotportImprint: '/impressum',
}

export const apiPaths = {
    getDefectLine: '/bng/wg5x',
    getEasyLoginDeactivated: '/bng/wg54',
    getHotportError: '/hotport/error',
    getHotportWelcome: '/hotport/welcome',
    getHotportLoading: '/hotport/loading',
    getHotportSuccess: '/hotport/success',
    getHotportImprint: '/hotport/impressum',
}
