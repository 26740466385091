import React from 'react'

function TLogo(): React.ReactElement {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={73}
            height={36}
            viewBox="0 0 73 36"
            shapeRendering="geometricPrecision"
        >
            <title>{'Deutsche Telekom AG Brand Logo'}</title>
            <style>{'.prefix__st0{fill:#fff}'}</style>
            <g id="prefix__Logo_Dark">
                <path
                    className="prefix__st0"
                    d="M0 24v-7h7v7H0zm22 0v-7h7v7h-7zm22 0v-7h7v7h-7zm22 0v-7h7v7h-7z"
                />
                <path
                    className="prefix__st0"
                    d="M12 1.74c-2.94.09-5.56 1.09-7.22 2.98-1.57 1.8-2.55 4.6-2.91 8.32L0 12.71.36 0h28.29L29 12.71l-1.87.33c-.36-3.76-1.34-6.52-2.91-8.32-1.66-1.89-4.28-2.89-7.22-2.98V28.3c0 2.31.57 3.82 1.25 4.51.56.6 1.41.96 2.75 1.08.42.03 1.05.06 2 .06V36H6v-2.04c.95 0 1.58-.03 2-.06 1.34-.12 2.18-.48 2.75-1.08.68-.69 1.25-2.19 1.25-4.51V1.74z"
                />
            </g>
        </svg>
    )
}

export default TLogo
