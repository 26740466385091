import React from 'react'
import styled from 'styled-components'
import { space, position, PositionProps, SpaceProps } from 'styled-system'

export const LoaderTag = styled.div<PositionProps & SpaceProps>`
    @keyframes rotate {
        from {
            transform: rotate(0deg);
        }

        to {
            transform: rotate(360deg);
        }
    }

    display: inline-block;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid ${({ theme }) => theme.colors.gray[8]};
    border-top-color: transparent;
    animation: 1s infinite rotate;
    ${space}
    ${position}
`

interface LoaderProps extends PositionProps, SpaceProps {
    fillParent?: boolean
}

export const Loader = ({ fillParent, ...rest }: LoaderProps) => {
    if (fillParent) {
        return (
            <LoaderTag
                {...rest}
                position="absolute"
                top="50%"
                left="50%"
                mt={-20}
                ml={-20}
                data-qa="loading_Spinner"
            />
        )
    }
    return <LoaderTag {...rest} data-qa="loading_Spinner" />
}
