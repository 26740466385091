import React from 'react'
import styled from 'styled-components'
import { getHotportError } from 'src/api'
import { AbsoluteLink } from 'src/components/AbsoluteLink'
import Box from 'src/components/Box'
import { HotportPage } from 'src/components/HotportPage'
import { HotportText } from 'src/components/HotportText'
import { Loader } from 'src/components/Loader'
import { useAsync } from 'src/hooks/useAsync'
import { useDocumentTitle } from 'src/hooks/useDocumentTitle'
import { IHotportErrorProps } from 'src/typings/response'

const ErrorIcon = styled.div`
    display: inline-block;
    border: 2px solid ${({ theme }) => theme.colors.black};
    width: 36.6px;
    height: 36.6px;
    line-height: 36.6px;
    border-radius: 50%;
    font-weight: 700;
    font-size: 28px;
`

export const HotportError = () => {
    const { data, run } = useAsync<IHotportErrorProps>()
    useDocumentTitle(data?.tabTitle, [data])

    React.useEffect(() => {
        run(getHotportError())
    }, [])

    if (!data) {
        return <Loader fillParent />
    }

    const { title, subTitle, text, impressumLink } = data

    return (
        <HotportPage
            title={title}
            link={
                <Box textAlign="center" mt={{ default: 235, m: 250 }}>
                    <AbsoluteLink to={impressumLink.url} target="_blank" data-qa="link_Impressum">
                        {impressumLink.text}
                    </AbsoluteLink>
                </Box>
            }
        >
            <HotportText variant="plain" mb={1} data-qa="label_ErrorText">
                {subTitle}
            </HotportText>
            <ErrorIcon data-qa="icon_Error">!</ErrorIcon>
            <HotportText variant="plain" mt={1} data-qa="label_NoteError">
                {text}
            </HotportText>
        </HotportPage>
    )
}
