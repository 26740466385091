import styled, { css } from 'styled-components'
import { layout, space, textAlign } from 'styled-system'
import { CopyTextProps } from '.'

const Text = styled.p<CopyTextProps>`
    margin-top: 0;
    margin-bottom: 0;
    ${({ color, theme: { colors } }) => css`
        color: ${color || colors.gray[1]};
    `}
    ${layout}
    ${space}
    ${textAlign};
`

export const Copy = styled(Text)`
    ${({ theme: { fonts } }) => css`
        font: ${fonts.copy};
    `}
`

export const CopyHighlight = styled(Text)`
    ${({ theme: { fonts } }) => css`
        font: ${fonts.copyHighlight};
    `}
`

export const Additional = styled(Text)`
    ${({ theme: { fonts } }) => css`
        font: ${fonts.additional};
    `}
`

export const AdditionalHighlight = styled(Text)`
    ${({ theme: { fonts } }) => css`
        font: ${fonts.additionalHighlight};
    `}
`

export const AdditionalMicro = styled(Text)`
    ${({ theme: { fonts } }) => css`
        font: ${fonts.additionalMicro};
    `}
`
