import React from 'react'
import styled, { css } from 'styled-components'

const ButtonTag = styled.button`
    ${({ theme }) => css`
        height: 42px;
        line-height: 40px;
        padding: 0 29px;
        border-radius: 4px;
        border: 1px solid ${theme.colors.gray[4]};
        font-family: 'Trebuchet MS', sans-serif;
        font-size: 15px;
        background-color: ${theme.colors.white};
        cursor: pointer;

        &:hover {
            background-color: ${theme.colors.gray[12]};
        }

        &:active {
            background-color: ${theme.colors.gray[11]};
        }
    `}
`

interface ButtonProps {
    children: React.ReactNode
    onClick: () => void
    type?: 'button' | 'submit' | 'reset'
}

export function Button({ children, type = 'button', onClick, ...rest }: ButtonProps) {
    return (
        <ButtonTag {...rest} type={type} onClick={onClick}>
            {children}
        </ButtonTag>
    )
}
