import React, { useEffect } from 'react'
import ReactHtmlParser from 'react-html-parser'
import { getBngEasyLognDeactivated } from 'src/api'
import { AbsoluteLink } from 'src/components/AbsoluteLink'
import Box from 'src/components/Box'
import CopyText from 'src/components/CopyText'
import Header from 'src/components/Header'
import Heading from 'src/components/Heading'
import { Loader } from 'src/components/Loader'
import { useAsync } from 'src/hooks/useAsync'
import { useDocumentTitle } from 'src/hooks/useDocumentTitle'
import ErrorImage from 'src/images/ErrorImage'
import theme from 'src/theme'
import { IBngEasyLoginDeactivatedProps } from 'src/typings/response'
import { faviconChange } from 'src/utils/faviconChange'

function BngEasyLoginDeactivatedPage() {
    const { data, run } = useAsync<IBngEasyLoginDeactivatedProps>()
    useDocumentTitle(data?.headline, [data])

    useEffect(() => {
        run(getBngEasyLognDeactivated())

        faviconChange('/mein-kundencenter/bng/favicon.png')
    }, [run])

    if (!data) {
        return <Loader fillParent />
    }

    const { headline, texts, impressumLink } = data

    return (
        <>
            <Header data-qa="block_Header" />
            <Box
                maxWidth={{ l: '996px' }}
                ml={{ default: '10%', l: 'auto' }}
                mr={{ default: '10%', l: 'auto' }}
                mt={{ default: 3, l: 5 }}
            >
                <Heading
                    variant="primary"
                    tag="h1"
                    mb={2}
                    mt={{ default: 0, l: 6 }}
                    textAlign="center"
                >
                    {headline}
                </Heading>
                <Box maxWidth={{ l: '637px' }} m="auto" textAlign="center" mb={4} data-qa="text">
                    {texts.map(text => (
                        <CopyText key={text} variant="copy" color={theme.colors.gray[2]}>
                            {ReactHtmlParser(text)}
                        </CopyText>
                    ))}
                </Box>
                <Box
                    maxWidth="664px"
                    ml="auto"
                    mr="auto"
                    mb={{ default: 5, l: 7 }}
                    data-qa="image_Error"
                >
                    <ErrorImage />
                </Box>
            </Box>
            <Box mt={5} mb={5} textAlign="center">
                <AbsoluteLink to={impressumLink.url} target="_blank" data-qa="link_Impressum">
                    {impressumLink.text}
                </AbsoluteLink>
            </Box>
        </>
    )
}

export default BngEasyLoginDeactivatedPage
