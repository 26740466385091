import styled from 'styled-components'
import {
    flexbox,
    FlexboxProps,
    layout,
    LayoutProps,
    position,
    PositionProps,
    shadow,
    ShadowProps,
    space,
    SpaceProps,
    typography,
    TypographyProps,
    border,
    BorderProps,
} from 'styled-system'

const Box = styled.div<
    FlexboxProps &
        LayoutProps &
        PositionProps &
        ShadowProps &
        SpaceProps &
        TypographyProps &
        BorderProps
>`
    ${flexbox}
    ${layout}
    ${position}
    ${shadow}
    ${space}
    ${typography}
    ${border}
`

export default Box
