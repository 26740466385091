import React, { useEffect } from 'react'
import { getBngDefectLine } from 'src/api'
import { AbsoluteLink } from 'src/components/AbsoluteLink'
import Box from 'src/components/Box'
import CopyText from 'src/components/CopyText'
import Heading from 'src/components/Heading'
import { Loader } from 'src/components/Loader'
import { useAsync } from 'src/hooks/useAsync'
import { useDocumentTitle } from 'src/hooks/useDocumentTitle'
import colors from 'src/theme/colors'
import { IBngDefectLineProps } from 'src/typings/response'

function BngDefectLinePage() {
    const { data, run } = useAsync<IBngDefectLineProps>()
    useDocumentTitle(data?.headline, [data])

    useEffect(() => {
        run(getBngDefectLine())
    }, [run])

    if (!data) {
        return <Loader fillParent />
    }

    const { headline, text, impressumLink } = data

    return (
        <Box
            maxWidth={{ l: '996px' }}
            ml={{ default: '10%', l: 'auto' }}
            mr={{ default: '10%', l: 'auto' }}
            mt={{ default: 3, l: 5 }}
        >
            <Heading variant="primary" tag="h1" mb={2} mt={{ default: 0, l: 6 }} textAlign="center">
                {headline}
            </Heading>
            <CopyText
                maxWidth={{ l: '637px' }}
                m="auto"
                textAlign="center"
                variant="copy"
                color={colors.gray[2]}
                data-qa="text"
            >
                {text}
            </CopyText>
            <Box mt={5} textAlign="center">
                <AbsoluteLink to={impressumLink.url} target="_blank" data-qa="link_Impressum">
                    {impressumLink.text}
                </AbsoluteLink>
            </Box>
        </Box>
    )
}

export default BngDefectLinePage
