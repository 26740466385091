export interface Breakpoints {
    default: string
    s: string
    m: string
    l: string
    xl: string
}

const breakpoints: Breakpoints = {
    default: '0',
    s: '320px',
    m: '640px',
    l: '1024px',
    xl: '1344px',
}

export default breakpoints
