import React from 'react'
import { LayoutProps, SpaceProps, TextAlignProps } from 'styled-system'
import * as S from './styles'

type Variant = 'plain' | 'plain-large' | 'heading' | 'heading-small'
type Tags = 'p' | 'h1' | 'h2' | 'h3'

const getVariant = (variant: Variant): [React.ElementType, Tags] => {
    switch (variant) {
        case 'plain':
            return [S.HotportTextPlain, 'p']
        case 'plain-large':
            return [S.HotportTextPlainLarge, 'p']
        case 'heading':
            return [S.HotportTextHeading, 'h1']
        case 'heading-small':
            return [S.HotportTextHeadingSmall, 'h3']
        default:
            throw Error('unexpected HotporText variant type')
    }
}

interface HotportTextProps extends LayoutProps, SpaceProps, TextAlignProps {
    variant: Variant
    tag?: Tags
    children?: React.ReactNode
}

export const HotportText = ({ children, variant, tag, ...rest }: HotportTextProps) => {
    const [StyledComponent, defaultTag] = getVariant(variant)

    return (
        <StyledComponent as={tag ?? defaultTag} {...rest}>
            {children}
        </StyledComponent>
    )
}

export { HotportTextContainer } from './styles'
