import React from 'react'
import ReactHtmlParser from 'react-html-parser'
import styled, { css } from 'styled-components'
import { getHotportImprint } from 'src/api'
import { HotportPage } from 'src/components/HotportPage'
import { HotportText } from 'src/components/HotportText'
import { Loader } from 'src/components/Loader'
import { useAsync } from 'src/hooks/useAsync'
import { useDocumentTitle } from 'src/hooks/useDocumentTitle'
import { IHotportImprintProps } from 'src/typings/response'

export const Block = styled.div`
    ${({ theme }) => css`
        &:not(:last-child) {
            margin-bottom: ${theme.bu * 4}px;
        }
    `}
`

export const HotportImprint = () => {
    const { data, run } = useAsync<IHotportImprintProps>()
    useDocumentTitle(data?.tabTitle, [data])

    React.useEffect(() => {
        run(getHotportImprint())
    }, [])

    if (!data) {
        return <Loader fillParent />
    }

    const { title, blocks } = data

    return (
        <HotportPage title={title} mb={{ default: 5, m: 140 }} textAlign="left">
            <div style={{ textAlign: 'left' }}>
                {blocks.map((block, id) => (
                    <Block key={`${block}-${id}`}>
                        <HotportText
                            variant="heading-small"
                            mb={2}
                            data-qa={`label_block${id}Header`}
                        >
                            {ReactHtmlParser(block.title)}
                        </HotportText>
                        <HotportText variant="plain" data-qa={`label_block${id}Text`}>
                            {ReactHtmlParser(block.text)}
                        </HotportText>
                    </Block>
                ))}
            </div>
        </HotportPage>
    )
}
