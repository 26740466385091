import bu from './buildingUnit'

interface FontParams {
    fontWeight: 100 | 400 | 500 | 700 | 900
    fontSize: number // in building units
    lineHeight: number // in building units
}

const createFont = ({ fontWeight, fontSize, lineHeight }: FontParams) =>
    `${fontWeight} ${fontSize * bu}px/${lineHeight * bu}px TeleNeo, sans-serif`

export interface Fonts {
    heroHeading: string
    heroDisplayHeading: string
    primaryHeading: string
    primaryDisplayHeading: string
    secondaryHeading: string
    secondaryDisplayHeading: string
    tertiaryHeading: string
    tertiaryHeadingHighlight: string
    tertiaryDisplayHeading: string
    quaternaryHeading: string
    quaternaryHeadingHighlight: string
    quaternaryDisplayHeading: string
    copy: string
    copyHighlight: string
    additional: string
    additionalHighlight: string
    additionalMicro: string
}

const fonts: Fonts = {
    heroHeading: createFont({ fontWeight: 400, fontSize: 5, lineHeight: 5 }),
    heroDisplayHeading: createFont({ fontWeight: 900, fontSize: 5, lineHeight: 5 }),
    primaryHeading: createFont({ fontWeight: 400, fontSize: 4, lineHeight: 4 }),
    primaryDisplayHeading: createFont({ fontWeight: 900, fontSize: 4, lineHeight: 4 }),
    secondaryHeading: createFont({ fontWeight: 400, fontSize: 3, lineHeight: 3 }),
    secondaryDisplayHeading: createFont({ fontWeight: 900, fontSize: 3, lineHeight: 3 }),
    tertiaryHeading: createFont({ fontWeight: 400, fontSize: 2, lineHeight: 2.4166667 }),
    tertiaryHeadingHighlight: createFont({ fontWeight: 700, fontSize: 2, lineHeight: 2.4166667 }),
    tertiaryDisplayHeading: createFont({ fontWeight: 900, fontSize: 2, lineHeight: 2.4166667 }),
    quaternaryHeading: createFont({ fontWeight: 400, fontSize: 1.75, lineHeight: 2.0833333 }),
    quaternaryHeadingHighlight: createFont({
        fontWeight: 700,
        fontSize: 1.75,
        lineHeight: 2.0833333,
    }),
    quaternaryDisplayHeading: createFont({
        fontWeight: 900,
        fontSize: 1.75,
        lineHeight: 2.0833333,
    }),
    copy: createFont({ fontWeight: 400, fontSize: 1.5, lineHeight: 2 }),
    copyHighlight: createFont({ fontWeight: 700, fontSize: 1.5, lineHeight: 2 }),
    additional: createFont({ fontWeight: 400, fontSize: 1.25, lineHeight: 1.5 }),
    additionalHighlight: createFont({ fontWeight: 700, fontSize: 1.25, lineHeight: 1.5 }),
    additionalMicro: createFont({ fontWeight: 400, fontSize: 1, lineHeight: 1.1666667 }),
}

export default fonts
