import React from 'react'
import { SpaceProps, TextAlignProps } from 'styled-system'
import Box from 'src/components/Box'
import { HotportText, HotportTextContainer } from 'src/components/HotportText'
import bu from 'src/theme/buildingUnit'

interface HotportPageProps extends SpaceProps, TextAlignProps {
    title: string
    children: React.ReactNode
    link?: React.ReactNode
}

export const HotportPage = ({ title, children, link, ...rest }: HotportPageProps) => {
    return (
        <>
            <Box
                position="relative"
                maxWidth={{ m: '575px' }}
                ml={{ s: bu, m: 'auto' }}
                mr={{ s: bu, m: 'auto' }}
                mt={{ default: 80, m: 90 }}
                pl={{ s: bu, m: 40 }}
                pr={{ s: bu, m: 40 }}
                pb={{ default: 48, m: 37 }}
                pt={{ default: 54, m: 64 }}
                border="1px solid #979797"
                textAlign="center"
                {...rest}
            >
                <HotportTextContainer>
                    <HotportText
                        variant="heading"
                        mb={{ default: 0 }}
                        mt={{ default: 0 }}
                        textAlign="center"
                        data-qa="label_Header"
                    >
                        {title}
                    </HotportText>
                </HotportTextContainer>
                {children}
            </Box>
            {link}
        </>
    )
}
