import styled, { css } from 'styled-components'

export const Wrapper = styled.div`
    ${({ theme: { colors, breakpoints, bu } }) => css`
        display: flex;
        align-items: center;
        width: 100%;
        height: ${bu * 6}px;
        padding: 0 ${bu}px;
        background-color: ${colors.magenta};
        box-sizing: border-box;

        @media (min-width: ${breakpoints['m']}) {
            padding: 0 ${bu * 2}px;
        }
    `}
`

export const LogoWrapper = styled.div`
    margin-right: 32px;
`

export const SloganWrapper = styled.div`
    margin-top: 5px;
`
