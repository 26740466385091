import React from 'react'
import { Route, Switch } from 'react-router'
import BngDefectLinePage from 'src/pages/bng-defect-line'
import BngEasyLoginDeactivatedPage from 'src/pages/bng-easy-login-deactivated'
import Home from 'src/pages/devIndex'
import { pagePaths } from 'src/routes/bng'
import { Hotport } from './pages/hotport'
import { HotportError } from './pages/hotport-error'
import { HotportImprint } from './pages/hotport-imprint'

function App() {
    return (
        <Switch>
            <Route exact path={pagePaths.hotport} component={Hotport} />
            <Route path={pagePaths.hotportImprint} component={HotportImprint} />
            <Route path={pagePaths.hotportError} component={HotportError} />
            <Route path={pagePaths.easyLoginDeactivated} component={BngEasyLoginDeactivatedPage} />
            <Route path={pagePaths.defectLine} component={BngDefectLinePage} />
            {process.env.NODE_ENV === 'development' && <Route path="/home" component={Home} />}
        </Switch>
    )
}

export default App
