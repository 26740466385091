import React from 'react'

export const useDocumentTitle = (
    title = '',
    dependencies: React.DependencyList | undefined = [],
) => {
    React.useEffect(() => {
        document.title = title
    }, [...dependencies])
}
