import React, { FC } from 'react'
import TLogo from 'src/images/TLogo'
import TSlogan from 'src/images/TSlogan'
import * as S from './styles'

const Header: FC = ({ ...props }) => (
    <S.Wrapper {...props}>
        <S.LogoWrapper>
            <TLogo />
        </S.LogoWrapper>
        <S.SloganWrapper>
            <TSlogan />
        </S.SloganWrapper>
    </S.Wrapper>
)

export default React.memo(Header)
