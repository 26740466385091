import React from 'react'
import { AxiosError } from 'axios'

export type Status = 'idle' | 'pending' | 'success' | 'error'

interface Result<T> {
    status: Status
    data: T | null
    error: AxiosError | null
    run: (promise: Promise<T>) => void
    reset: () => void
}

/**
 * Custom hook, which is designed for user to make async calls and
 * also know the current state of request
 */
function useAsync<T>(): Result<T> {
    const [status, setStatus] = React.useState<Status>('idle')
    const [data, setData] = React.useState<T | null>(null)
    const [error, setError] = React.useState<AxiosError | null>(null)
    const mountedRef = React.useRef(false)
    React.useEffect(() => {
        mountedRef.current = true
        return () => {
            mountedRef.current = false
        }
    }, [])

    const run = React.useCallback((promise: Promise<T>) => {
        setStatus('pending')
        setData(null)
        setError(null)
        promise.then(
            (resolvedResult: T) => {
                if (mountedRef.current) {
                    setData(resolvedResult)
                    setStatus('success')
                }
            },
            rejectedReason => {
                if (mountedRef.current) {
                    setError(rejectedReason)
                    setStatus('error')
                }
            },
        )
    }, [])

    const reset = React.useCallback(() => {
        setStatus('idle')
        setData(null)
        setError(null)
    }, [])

    return {
        status,
        data,
        error,
        run,
        reset,
    }
}

export { useAsync }
